'use strict';

var animateBurgerIcon = function animateBurgerIcon() {
	var burgerAnimate = document.querySelectorAll('.js-burger-animate');

	burgerAnimate.forEach(function (item) {
		item.addEventListener('click', function () {
			item.classList.toggle('is-active');
		});
	});
};

animateBurgerIcon();