'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * DotMap
 *
 * @param {HTMLElement} mapElement
 * @param {object} configObj
 *
 * @author pedro.britto@indexdigital.com.br (Pedro Britto)
 */
var DotMap = function () {
	function DotMap(mapElement, configObj) {
		_classCallCheck(this, DotMap);

		if (document.querySelector(mapElement)) {
			// Estados (uf)
			this.allStates = document.querySelectorAll('g[data-name]');
			this.selectableStates = [];
			this.statesDataset = 'name';

			// Classes
			this.isSelectableClass = configObj.selectableClass ? configObj.selectableClass : 'c-has';
			this.isClickedClass = configObj.clickedClass ? configObj.clickedClass : 'is-clicked';

			// Referencias para o tab management
			this.tabContainer = configObj.tabContainer ? document.querySelector(configObj.tabContainer) : document.querySelector('.k-tab-container');
			this.tabHead = this.selectableStates;
			this.tabContent = configObj.tabContent ? document.querySelectorAll(configObj.tabContent) : document.querySelectorAll('.k-tab-container .tab-content');
			this.tabClassName = configObj.className ? configObj.className : 'is-active';

			// Referencia o Pin do mapa
			this.mapPin = document.querySelector('#map-pin');
			this.mapPinUF = document.querySelector('#map-pin-uf');

			// Executa o código
			this.main();
		}
	}

	/**
  * Executa função
  */


	_createClass(DotMap, [{
		key: 'main',
		value: function main() {
			var _this = this;

			this.fetchStates().then(function (response) {
				return response.json();
			}).then(function (data) {
				_this.setSelectableStates(data);
				_this.addClassToSelectableStates();
				_this.stateClickHandler();
				_this.selectFirstState('uf-ce');

				// Tab Management
				_this.setTabHeadHandler();
				_this.setTabContentHandler();
			});
		}

		/**
   * Parse and filter fetch response, filter and push result to this.selectableStates
   * @param {*} response Resposta recebida
   */

	}, {
		key: 'setSelectableStates',
		value: function setSelectableStates(response) {
			var parsedResponse = response.estado.map(function (item) {
				return '#' + item;
			});

			for (var i = 0, n = this.allStates.length; i < n; i++) {
				var currentState = this.allStates[i];

				if (parsedResponse.indexOf(currentState.dataset[this.statesDataset]) !== -1) {
					this.selectableStates.push(currentState);
				}
			}
		}

		/**
   * Add classe aos estados selecionáveis.
   * @returns {void}
   */

	}, {
		key: 'addClassToSelectableStates',
		value: function addClassToSelectableStates() {
			var _this2 = this;

			this.selectableStates.map(function (element) {
				element.classList.add(_this2.isSelectableClass);
			});
		}

		/**
   * Seleciona primeiro estado
   * @param {string} stateString No modelo uf-xx
   */

	}, {
		key: 'selectFirstState',
		value: function selectFirstState(stateString) {
			var _this3 = this;

			var selectedState = void 0;

			this.selectableStates.map(function (item) {
				if (item.dataset[_this3.statesDataset].indexOf('#' + stateString) !== -1) selectedState = item;
			});

			// Move o pin para o elemento do estado
			this.movePin(selectedState);
			this.setPinUF(this.getUFFromElement(selectedState));

			// Add classe isClickedClass no elemento do estado
			selectedState.classList.add(this.isClickedClass);

			// Seleciona tab correspondente ao estado
			this.tabContent.forEach(function (element) {
				if (element.getAttribute('id') === stateString) element.classList.add('is-active');
			});
		}

		/**
   * Add evento de clique aos elementos clicáveis.
   * @returns {void}
   */

	}, {
		key: 'stateClickHandler',
		value: function stateClickHandler() {
			var _this4 = this;

			return this.selectableStates.map(function (element) {
				element.addEventListener('click', function () {
					_this4.movePin(element);
					_this4.setPinUF(_this4.getUFFromElement(element));
					_this4.removesClassFromAllAddsToClicked(element, _this4.selectableStates, _this4.isClickedClass);
				});
			});
		}

		/**
   * Retorna UF a partir de um data-name no formato #uf-XX
   *
   * @param {HTMLElement} element Elemento HTML que contém data-name
   */

	}, {
		key: 'getUFFromElement',
		value: function getUFFromElement(element) {
			var fullUF = element.dataset.name;
			var parsedUF = fullUF.split('-');
			return parsedUF[1].toUpperCase();
		}

		/**
   * Pega o valor da uf a partir do data- do elemento de um estado
   * @param {HTMLElement} stateElement
   * @returns {string} UF do estado
   */

	}, {
		key: 'getStateUfFromHTMLElement',
		value: function getStateUfFromHTMLElement(stateElement) {
			var stateUfDataset = stateElement.dataset[this.statesDataset];
			var parseStateUfDataset = stateUfDataset.split('-');
			var stateUF = parseStateUfDataset[1];

			return stateUF;
		}

		/**
   * Moves pin to clicked state
   * @param {HTMLElement} clickedElement - Where the pin will move to
   */

	}, {
		key: 'movePin',
		value: function movePin(clickedElement) {
			var clickedStateBoudingClientRect = clickedElement.getBoundingClientRect();
			var pinBoudingClientRect = this.mapPin.getBoundingClientRect();

			var clickedCoords = {
				height: Math.floor(clickedStateBoudingClientRect.height),
				width: Math.floor(clickedStateBoudingClientRect.width),
				x: Math.floor(clickedStateBoudingClientRect.x + window.scrollX),
				y: Math.floor(clickedStateBoudingClientRect.y + window.scrollY)
			};
			var pinCoords = {
				height: Math.floor(pinBoudingClientRect.height),
				width: Math.floor(pinBoudingClientRect.width),
				x: Math.floor(pinBoudingClientRect.x + window.scrollX),
				y: Math.floor(pinBoudingClientRect.y + window.scrollY)
			};

			if (this.mapPin.style.opacity !== 1) this.mapPin.style.opacity = 1;

			this.mapPin.style.transform = 'translateX(' + (clickedCoords.x + clickedCoords.width / 2 - pinCoords.width / 2) + 'px)' + ' translateY(' + (clickedCoords.y + clickedCoords.height / 2 - pinCoords.height) + 'px)';
		}

		/**
   *
   */

	}, {
		key: 'setPinUF',
		value: function setPinUF(stringToShow) {
			return this.mapPinUF.textContent = stringToShow;
		}

		/**
   * Get states from wordpress API
   */

	}, {
		key: 'fetchStates',
		value: function fetchStates() {
			return fetch('http://homolog.indexvirtual.com/sp-combustiveis/wp-json/wp/config');
		}

		/*********************
   * Utility Functions *
   *********************/

		/**
   * Retorna todos os estados
   * @return {object} this.allStates
   */

	}, {
		key: 'getAllStates',
		value: function getAllStates() {
			return this.allStates;
		}

		/**
   * Retorna todos os estados selecionáveis
   * @return {object} this.selectableStates
   */

	}, {
		key: 'getSelectableStates',
		value: function getSelectableStates() {
			return this.selectableStates;
		}

		/**
   * Remove uma classe de todas as keys de object,
   * Add mesma classe ao elemento clicado
   */

	}, {
		key: 'removesClassFromAllAddsToClicked',
		value: function removesClassFromAllAddsToClicked(clickedElement, objectToIterate, className) {
			for (var key in objectToIterate) {
				if (objectToIterate.hasOwnProperty(key)) {
					var element = objectToIterate[key];
					element.classList.remove(className);
				}
			}
			clickedElement.classList.add(className);
		}

		/**************************
   * TAB MANAGEMENT SECTION *
   **************************/

		/**
   * Adds .is-active to every clicked state's <g> element
   * @returns {void}
   */

	}, {
		key: 'setTabHeadHandler',
		value: function setTabHeadHandler() {
			var _this5 = this;

			this.selectableStates.forEach(function (element) {
				element.addEventListener('click', function () {
					return _this5.removesClassFromAllAddsToClicked(element, _this5.selectableStates, _this5.tabClassName);
				});
			});
		}

		/**
   * Adds .is-active to each tabContent after tabItem click
   * @returns {void}
   */

	}, {
		key: 'setTabContentHandler',
		value: function setTabContentHandler() {
			var _this6 = this;

			this.selectableStates.forEach(function (element) {
				element.addEventListener('click', function () {
					_this6.tabContent.forEach(function (item) {
						item.classList.remove(_this6.tabClassName);
					});

					// <div data-name="#name"></div> will select
					// <div id="name"></div>
					if (document.querySelector(element.dataset[_this6.statesDataset]) && element.classList.contains('c-has')) {
						document.querySelector(element.dataset[_this6.statesDataset]).classList.add(_this6.tabClassName);
					} else console.warn('There\'s no .tab-content with ' + element.dataset[_this6.statesDataset] + '.');
				});
			});
		}
	}]);

	return DotMap;
}();

// Create DotMap Instances


window.addEventListener('load', function () {
	new DotMap('#map-dot', {
		tabContainer: '.dot-map-tab-container'
	});
});