'use strict';

var makeStickyFooter = function makeStickyFooter() {
	var footerHeightVar = '--footer-height';
	var footerHeight = document.querySelector('.footer');

	function getFooterHeight() {
		return footerHeight.offsetHeight;
	}

	function setFooterHeight() {
		console.log('Seting --footer-height as ' + getFooterHeight() + 'px');
		return document.documentElement.style.cssText = footerHeightVar + ': ' + getFooterHeight() / 10 + 'rem';
	}

	try {
		setFooterHeight();
	} catch (error) {
		console.warn('".footer" wasn\'t found.');
	}
};

makeStickyFooter();