'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var KamiModal = function () {
	function KamiModal() {
		_classCallCheck(this, KamiModal);

		this.kamiModals = this.getElementWithAttribute('data-k-modal');
		this.kamiModalOpen = this.getElementWithAttribute('data-k-modal-open');
		this.kamiModalClose = this.getElementWithAttribute('data-k-modal-close');

		this.main();
	}

	_createClass(KamiModal, [{
		key: 'main',
		value: function main() {
			this.openOnClick();
			this.closeOnClick();
			this.removeOnBgClick();
			this.closeOnEscPress();
			// this.addClassToBody();
		}
	}, {
		key: 'getElementWithAttribute',
		value: function getElementWithAttribute(attributeName) {
			var matchingElements = [];
			var allElements = document.getElementsByTagName('*');

			Array.from(allElements).map(function (item) {
				if (item.getAttribute(attributeName) !== null) {
					matchingElements.push(item);
				}
			});

			return matchingElements;
		}
	}, {
		key: 'addClassToBody',
		value: function addClassToBody() {
			var body = document.body;

			body.addEventListener('click', function () {
				body.classList.toggle('is-scroll-locked');
			});
		}
	}, {
		key: 'removeOnBgClick',
		value: function removeOnBgClick() {
			this.kamiModals.map(function (item) {
				var kModalBg = item.querySelector('.k-modal__bg');
				kModalBg.addEventListener('click', function () {
					return item.classList.remove('is-visible');
				});
			});
		}
	}, {
		key: 'openOnClick',
		value: function openOnClick() {
			var _this = this;

			this.kamiModalOpen.map(function (item) {
				var kamiModals = _this.kamiModals;

				item.addEventListener('click', function () {
					var kModalOpenId = this.dataset.kModalOpen;

					kamiModals.map(function (item) {
						if (item.getAttribute('data-k-modal') == kModalOpenId) item.classList.add('is-visible');
					});
				});
			});
		}
	}, {
		key: 'closeOnClick',
		value: function closeOnClick() {
			var _this2 = this;

			this.kamiModalClose.map(function (item) {
				var kamiModals = _this2.kamiModals;

				item.addEventListener('click', function () {
					var kModalCloseId = this.dataset.kModalClose;

					kamiModals.map(function (item) {
						if (item.getAttribute('data-k-modal') == kModalCloseId) item.classList.remove('is-visible');
					});
				});
			});
		}
	}, {
		key: 'closeOnEscPress',
		value: function closeOnEscPress() {
			var _this3 = this;

			document.addEventListener('keydown', function (e) {
				if (e.keyCode === 27) _this3.kamiModals.map(function (item) {
					return item.classList.remove('is-visible');
				});
			});
		}
	}]);

	return KamiModal;
}();

new KamiModal();