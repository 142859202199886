'use strict';

function EasyModal(modal, modalOpen, modalClose) {
	var modal = document.querySelector(modal);
	var modalOpen = document.querySelectorAll(modalOpen);
	var modalClose = document.querySelector(modalClose);
	var body = document.body;

	function removeClasses() {
		modal.classList.remove('is-active');
		document.body.classList.remove('modal-is-active');
	}

	// Check for modal components in page
	if (modal && modalOpen && modalClose) {
		// Open Modal
		modalOpen.forEach(function (item) {
			item.addEventListener('click', function (e) {
				e.preventDefault();
				modal.classList.add('is-active');
				document.body.classList.add('modal-is-active');
			});
		});

		// Close Modal
		modalClose.addEventListener('click', function () {
			removeClasses();
		});

		// Close Modal on esc
		window.addEventListener('keydown', function (e) {
			if (e.keyCode === 27) removeClasses();
		});
	}
}

var clientes = new EasyModal('.modal', '.js-modal-open', '.js-modal-close');
var modalGaleria = new EasyModal('.modal-gallery', '.js-modal-open-gallery', '.js-modal-close-gallery');