'use strict';

var homeNewsAccordion = function homeNewsAccordion() {
	var accordionElement = document.getElementsByClassName('accordion');

	Array.from(accordionElement).map(function (item) {
		item.addEventListener('click', function () {
			var accordionContent = this.nextElementSibling;
			var accordionContentDisplay = getComputedStyle(accordionContent).getPropertyValue('display');

			if (accordionContentDisplay === 'block') accordionContent.style.display = 'none';else accordionContent.style.display = 'block';

			// Inverte ícone
			var angleIcon = item.querySelector('i');
			angleIcon.classList.toggle('fa-flip-vertical');
			angleIcon.classList.toggle('bounce');
		});
	});
};

homeNewsAccordion();