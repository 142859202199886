'use strict';

/**
 * @param {string|HTMLElement} swiperElement Elemento Swiper que está dentro do modal
 * @param {string} elementWithData
 * @param {string} dataName
 */
var modalSlider = function modalSlider(swiperElement, elementWithData, dataName) {
	var slider = new Swiper(swiperElement, {
		slidesPerView: 1,
		centeredSlides: true,
		spaceBetween: 50,
		keyboard: {
			enabled: true
		},
		navigation: {
			nextEl: swiperElement + ' .button-next',
			prevEl: swiperElement + ' .button-prev'
		}
	});

	/**
  * @param {string} element
  */
	function getProjectId(element) {
		var projectItems = document.querySelectorAll(element);

		Array.from(projectItems).map(function (item) {
			item.addEventListener('click', function (e) {
				e.preventDefault();
				slider.slideTo(this.dataset[dataName] - 1);
			});
		});
	};

	getProjectId(elementWithData);
};

modalSlider('.galeria-noticia-slider', '.galeria-noticia', 'projectId');
modalSlider('.media-video-slider', '.media-video-item', 'projectId');
modalSlider('.media-impressos-slider', '.media-impressos-item', 'projectId');