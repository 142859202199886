'use strict';

var initSliders = function initSliders() {
	// Objetos de configuração para swiper
	var config = {
		sliderListingDefault: {
			slidesPerView: 3,
			clickable: true,
			navigation: {
				nextEl: '.slider-listing-default .swiper-button-next',
				prevEl: '.slider-listing-default .swiper-button-prev'
			},

			breakpoints: {
				758: {
					slidesPerView: 1,
					spaceBetween: 20
				}
			}
		},
		sliderBox: {
			slidesPerView: 2,
			navigation: {
				nextEl: '.slider-box .swiper-button-next',
				prevEl: '.slider-box .swiper-button-prev'
			},

			breakpoints: {
				758: {
					slidesPerView: 1,
					spaceBetween: 20
				}
			}
		},
		sliderMedia: {
			slidesPerView: 3,
			navigation: {
				nextEl: '.slider-box .swiper-button-next',
				prevEl: '.slider-box .swiper-button-prev'
			},

			breakpoints: {
				758: {
					slidesPerView: 1,
					spaceBetween: 20
				}
			}
		},
		sliderExternalButton: {
			slidesPerView: 3,
			clickable: true,
			navigation: {
				nextEl: '.swiper-external-buttons .swiper-buttons-wrapper .swiper-button-next',
				prevEl: '.swiper-external-buttons .swiper-buttons-wrapper .swiper-button-prev'
			},

			breakpoints: {
				758: {
					slidesPerView: 1,
					spaceBetween: 20
				}
			}
		}
	};

	// Instâncias do swiper
	new Swiper('.slider-listing-default', config.sliderListingDefault);
	// new Swiper('.slider-box', config.sliderBox);
	new Swiper('.slider-media', config.sliderMedia);

	// Instâncias do swiper com configuração customizada

	// Sliders do tipo 'swiper-external-buttons' devem ser declarados com class/id únicos
	// que precisam ser utilizados para inicializar e selecionar os botões next/prev
	new Swiper('.swiper-external-buttons.slider-recent-posts .swiper-container', {
		slidesPerView: 3,
		navigation: {
			nextEl: '.swiper-external-buttons.slider-recent-posts .swiper-button-next',
			prevEl: '.swiper-external-buttons.slider-recent-posts .swiper-button-prev'
		},
		breakpoints: {
			758: {
				slidesPerView: 1,
				spaceBetween: 20
			}
		}
	});

	new Swiper('.swiper-external-buttons.slider-box .swiper-container', {
		slidesPerView: 2,
		spaceBetween: 10,
		navigation: {
			nextEl: '.swiper-external-buttons.slider-box .swiper-button-next',
			prevEl: '.swiper-external-buttons.slider-box .swiper-button-prev'
		},
		breakpoints: {
			758: {
				slidesPerView: 1,
				spaceBetween: 20
			}
		}
	});

	new Swiper('.slider-header', {
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		}
	});

	var galleryTop = new Swiper('.gallery-top', {
		spaceBetween: 10,
		loop: true,
		loopedSlides: 5,
		slidesPerView: 1,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		}
	});

	var galleryThumbs = new Swiper('.gallery-thumbs', {
		spaceBetween: 10,
		loop: true,
		loopedSlides: 5,
		centeredSlides: true,
		slidesPerView: 'auto',
		touchRatio: 0.2,
		slideToClickedSlide: true
	});

	var galleryThumbsEvents = new Swiper('.gallery-thumbs-events', {
		spaceBetween: 10,
		loop: true,
		loopedSlides: 4,
		centeredSlides: true,
		slidesPerView: '4',
		touchRatio: 0.2,
		slideToClickedSlide: true
	});

	if (document.querySelector('.gallery-top') && document.querySelector('.gallery-thumbs')) {
		Array.from(galleryTop).map(function (item, index) {
			galleryTop[index].controller.control = galleryThumbs[index];
			galleryThumbs[index].controller.control = galleryTop[index];
		});
	}

	if (document.querySelector('.gallery-top') && document.querySelector('.gallery-thumbs-events')) {
		galleryTop.controller.control = galleryThumbsEvents;
		galleryThumbsEvents.controller.control = galleryTop;
	}
};
initSliders();

// Multiple external slides
// Usado com multiplas instancias iguais, como nos sliders do mapa
var multipleExternalSlides = function multipleExternalSlides() {
	var allSwipers = document.querySelectorAll('.swiper-external-buttons.js-map-tab-slider');
	var swiperButtons = [];
	var swiperSlides = [];

	allSwipers.forEach(function (element) {
		swiperButtons.push(element.querySelector('.swiper-buttons-wrapper'));
		swiperSlides.push(element.querySelector('.swiper-container'));
	});

	for (var i = 0, n = swiperButtons.length; i < n; i++) {
		new Swiper(swiperSlides[i], {
			slidesPerView: 2,
			navigation: {
				nextEl: swiperButtons[i].querySelector('.swiper-button-next'),
				prevEl: swiperButtons[i].querySelector('.swiper-button-prev')
			},
			breakpoints: {
				758: {
					slidesPerView: 1,
					spaceBetween: 20
				}
			}
		});
	}
};
multipleExternalSlides();