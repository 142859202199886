'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function resizeListingTitle() {
	var listingTitles = Array.from(document.querySelectorAll('.js-listing__title-variable-size'));

	function getBiggestHeight() {
		var heights = listingTitles.map(function (item) {
			return item.getBoundingClientRect().height;
		});

		return Math.max.apply(Math, _toConsumableArray(heights));
	}

	function setHeight() {
		listingTitles.map(function (item) {
			item.style.height = getBiggestHeight() + 'px';
		});
	}

	setHeight();
}

resizeListingTitle();