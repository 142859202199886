'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MenuToggle = function () {
	function MenuToggle(obj) {
		_classCallCheck(this, MenuToggle);

		this.menuToggle = document.querySelectorAll(obj.menuToggle);
		this.menuElement = document.querySelector(obj.menuElement);
		this.activeBodyClass = obj.activeBodyClass ? obj.activeBodyClass : undefined;
		this.activeClass = obj.activeClass ? obj.activeClass : 'is-active';

		this.main();
	}

	_createClass(MenuToggle, [{
		key: 'main',
		value: function main() {
			var _this = this;

			this.menuToggle.forEach(function (item) {
				item.addEventListener('click', function () {
					_this.setBodyClass(_this.activeBodyClass);
					_this.setActiveClass(_this.menuElement, _this.activeClass);
				});
			});
		}
	}, {
		key: 'setBodyClass',
		value: function setBodyClass(bodyClass) {
			if (bodyClass) {
				return document.body.classList.toggle(bodyClass);
			}
		}
	}, {
		key: 'setActiveClass',
		value: function setActiveClass(element, activeClass) {
			return element.classList.toggle(activeClass);
		}
	}]);

	return MenuToggle;
}();

new MenuToggle({
	menuToggle: '.js-activate-nav-menu--mobile',
	menuElement: '.js-nav-menu--mobile-container',
	activeBodyClass: 'nav-menu--mobile-is-active'
});

new MenuToggle({
	menuToggle: '.js-activate-nav-menu',
	menuElement: '.js-nav-menu-container',
	activeBodyClass: 'desktop-menu-is-active'
});